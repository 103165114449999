import { getStatusCode, handleAxiosError } from '@/components/api/error';
import Instance from '@/components/api/instance';
import { listener, ref } from '@/components/utils/dom';
import { justValidate } from '@/plugins/@just-validate';
import { email, required } from '@/plugins/@just-validate/rules';
import { toast } from '@/plugins/@toaster';
import type { ToastType } from '@/plugins/@toaster/types';
import type { SlButton } from '@shoelace-style/shoelace';
import { serialize } from '@shoelace-style/shoelace';
import type JustValidate from 'just-validate';

enum Globals {
	Form = "[data-login='form']",
	Form_email = "[name='form-email']",
	Form_password = "[name='form-password']",
	Submit = "[data-login='form-submit']",
	Provider = "[data-login='google']",
}

enum StatusCode {
	Unauthorized = 401,
	UnprocessableEntity = 422,
	TooManyRequests = 429,
}

interface StatusMessage {
	title: string;
	description: string;
	type: ToastType;
}

const statusMessage: Record<StatusCode, StatusMessage> = {
	[StatusCode.Unauthorized]: {
		title: 'Accès non autorisé !',
		description: 'Saisissez un e-mail et un mot de passe valides.',
		type: 'warning',
	},
	[StatusCode.UnprocessableEntity]: {
		title: 'Compte inactif !',
		description: 'Activez-le en cliquant sur le lien envoyé par e-mail.',
		type: 'default',
	},
	[StatusCode.TooManyRequests]: {
		title: 'Trop de tentatives de connexion !',
		description:
			'Vous avez effectué trop de connexions. Veuillez réessayer plus tard.',
		type: 'info',
	},
};

(async (): Promise<void> => {
	const urlParams: URLSearchParams = new URLSearchParams(
		window.location.search,
	);

	const formInstance: Instance = new Instance('/api');
	const formValidate: JustValidate = justValidate(Globals.Form);
	const formContainer: HTMLFormElement | null = ref(Globals.Form);

	if (!formContainer) {
		return;
	}

	const formSubmit: SlButton | null = ref(Globals.Submit, formContainer);

	formValidate
		.addField(Globals.Form_email, email)
		.addField(Globals.Form_password, required)
		.onSuccess(async (event: Event | undefined): Promise<void> => {
			event!.preventDefault();

			formSubmit?.setAttribute('loading', 'true');

			const { 'form-email': username, 'form-password': password } =
				serialize(formContainer);

			try {
				await formInstance.Post('/post/connexion', {
					username,
					password,
				});

				document.location.href = urlParams.get('callback') || '/mon-compte';
			} catch (error: any) {
				const code: StatusCode = getStatusCode(error);

				const { title, description, type } = statusMessage[code] || {
					title: 'Une erreur est survenue !',
					description: 'Veuillez réessayer plus tard.',
					type: 'danger',
				};

				toast(title, { description, type, position: 'top-right' });

				await handleAxiosError(error);
			} finally {
				formSubmit?.removeAttribute('loading');
			}
		});

	// login provider
	const formProvider: SlButton | null = ref(Globals.Provider);

	if (!formProvider) {
		return;
	}

	listener(formProvider, 'click', (): void => {
		formProvider.setAttribute('loading', 'true');
		document.location.href = `/se-connecter/google?callback=${urlParams.get(
			'callback',
		)}`;
	});
})();

// HMR (Hot Module Replacement)
if (module.hot) {
	module.hot.accept();
	module.hot.dispose((): void => {
		console.clear();
	});
}
